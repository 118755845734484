<template>
  <div>
    <div class="pageHomeViewByModuleNormal__header">
      <CommonBoxModule :module="data" />
    </div>

    <div class="pageHomeViewByModuleNormal__container">
      <!-- Loading -->
      <template v-if="loading">
        <div class="pageHomeViewByModuleNormal__container--box" v-for="i in 4" :key="i">
          <CommonSkeletonsCover />
        </div>
      </template>

      <!-- Normal View -->
      <template v-else>
        <div class="pageHomeViewByModuleNormal__container--box" v-for="(item, index) in filterOnShow(data.modules)" :key="index">
          <div :data-testid="`container-box-${index}`">
              <CommonCardsModule 
                :data="{ ...item, course: { has_access: data.has_access, is_liberated: data.is_liberated, liberated_at: data.liberated_at } }"
                @show="checkPermission(item)"
                :no-cover="data.cover"
              />
              
              <CommonCardsTitle 
                @show="checkPermission(item)"
                :data="{ ...item, course: { has_access: data.has_access, is_liberated: data.is_liberated, liberated_at: data.liberated_at } }"
              />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    CommonBoxModule: require("@/components/common/box/Module.vue").default,
    CommonCardsModule: require("@/components/common/cards/Module.vue").default,
    CommonCardsTitle: require("@/components/common/cards/title/Title.vue").default,
    CommonSkeletonsCover: require("@/components/common/skeletons/Cover.vue").default
  },
  computed: {
    ...mapGetters({
      getIsAdmin: "config/getIsAdmin",
      getShowRating: "config/getShowRating",
      getMovieCoverMode: "config/getMovieCoverMode",
    }),
    estiloComponente() {
      return {
        "--width-card": this.widthCard,
      };
    },
  },
  methods: {
    ...mapActions({
      actionFetchCourseAvailability: "home/actionFetchCourseAvailability",
    }),
    filterOnShow(modules) {
      return modules?.filter((item) => item?.should_show === true)
    },

    checkPermission(item) {
      if (this.data.has_access === false) {
        return false
      }

      if(item.has_access === true && item.is_liberated === false) {
        return false
      }

      if(this.data.has_access === true && item.is_liberated === true) {
        this.$router.push({ path: `/curso/${item.course_id}/modulo/${item.id}` })
      }
    },

    allowCourse(item) {
      return (
        this.data?.is_liberated === item ||
        (this.data?.is_liberated === item && item)
      );
    },

    async denyCourse(item) {

      const response = await this.actionFetchCourseAvailability(item?.course_id);

      const fragment = {
        type: await response?.liberated_at ? "module" : "course",
        allow: await response?.is_liberated === false || item?.is_liberated === false ? false : true,
        date: item.liberated_at !== null ? item.liberated_at : await response?.liberated_at,
      };

      const data = {
        ...fragment,
        type: fragment.type === "course" ? 1 : 4,
      }

      this.$emit("show", data);
    },
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      default: () => [{}],
    },
    poster: {
      type: Boolean,
    },
    widthCard: {
      type: String,
      default: "153px",
    },
  },
};
</script>

<style lang="scss" scoped>
.pageHomeViewByModuleNormal {
  &__header {
    @include flex-row;
    width: 100%;
  }

  &__container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    &--box {
      max-width: v-bind("widthCard");
      flex-basis: calc(100% / 6);
      box-sizing: border-box;

      @media screen and (max-width: 768px) {
        flex-basis: calc((100% / 3) - 8px);
      }

      @media screen and (max-width: 480px) {
        flex-basis: calc((100% / 2) - 6px);
      }

      @media screen and (max-width: 310px) {
        flex-basis: 100%;
      }
    }
  }
}
</style>
